import { z } from "zod";

/** 売上集計結果 */
export const schemaSale = z.object({
	date: z.string().optional(),
	sku: z.string(),
	name: z.string(),
	quantity: z.number(),
	totalPrice: z.number().optional(),
	byMethods: z.record(z.string(), z.number()).optional(),
});
export type Sale = z.infer<typeof schemaSale>;