import dayjs from 'dayjs';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useCSV, useLocationGroup, useShipping } from '../../../hooks';
import { ShippingItemForShipping } from '../../../types';

export const PrintTotalPickingList: React.FC = () => {
	const [shippingCount, setShippingCount] = React.useState<number>(0);
	const [itemPerPages, setItemPerPages] = React.useState<ShippingItemForShipping[][]|null>(null);
	const location = useLocation();

	const [locationGroup] = useLocationGroup();
	const locationNameMap = new Map(locationGroup?.locations.map(loc => [loc.id, loc.name]));
	const [, , , , , shippingMethods] = useShipping(locationGroup?.id, {});
	const { parseDate } = useCSV();

	const params = new URLSearchParams(location.search);
	const ids = params.getAll('ids');
	const skuPrefix = params.get('skuPrefix') ?? '';
	const perPage = 30;

	React.useEffect(() => {
		// CSS調整
		document.head.querySelectorAll("link").forEach(link => link.remove());
		const css = document.createElement("link");
		css.rel = "stylesheet";
		css.href = `/css/pickinglist-print.css`;
		document.head.appendChild(css);

		// title調整
		const now = parseDate(new Date());
		document.title = `トータルピッキングリスト-${now?.year}${now?.mon}${now?.day}${now?.hour}${now?.min}`;
	}, []);

	React.useEffect(() => {
		if (ids.length <= 0) {
			return;
		}

		const load = async() => {
			const checks = Object.fromEntries(ids.map(id => [id, true]));
			const ss = await shippingMethods.getForShippings(checks);
			const items = ss.flatMap(shipping => shipping.items);
			const itemGroupby = items.reduce((summary, item) => {
				const s = summary.find(s => s.sku === item.sku && s.location_id === item.location_id);
				if (s) {
					s.quantity += typeof item.quantity === 'string' ? parseInt(item.quantity, 10) : item.quantity;
				} else {
					summary.push({
						...item,
						quantity: typeof item.quantity === 'string' ? parseInt(item.quantity, 10) : item.quantity,
					});
				}
				return summary;
			}, [] as ShippingItemForShipping[]);
			const pages = Math.ceil(itemGroupby.length / perPage);
			const itemPerPages = new Array(pages).fill(null)
				.map((_, i) => itemGroupby.slice(i * perPage, (i + 1) * perPage));
			setShippingCount(ss.length);
			setItemPerPages(itemPerPages);
		};

		load();

	}, []);

	if (!itemPerPages) {
		return null;
	}

	const outputDate = dayjs();

	return (
		<>
			{itemPerPages.map((items, page) =>
				<div className="page" key={page}>
					<section className="header">
						<h2>トータルピッキングリスト</h2>
						<div style={{ textAlign: 'right' }}>出力日時：{outputDate.format('YYYY-MM-DD HH:mm:ss')}</div>
						<div>{page + 1} / {itemPerPages.length}</div>
					</section>
					<section className="detail pb10">
						<table>
							<thead>
								<tr>
									<th className="w10 textC">#</th>
									<th className="w100">SKU</th>
									<th>商品名</th>
									<th className="w100">ロケーション</th>
									<th className="w80">個数</th>
								</tr>
							</thead>
							<tbody>
								{items.map((item, idx) =>
									<tr key={idx}>
										<td>{page * perPage + idx + 1}</td>
										<td>{skuPrefix}{item.sku}</td>
										<td>{item.name}</td>
										<td>{locationNameMap.get(item.location_id)}</td>
										<td>{item.quantity}</td>
									</tr>
								)}
							</tbody>
						</table>
					</section>
					{itemPerPages.length === page + 1 &&
						<section className="footer">
							<div>出荷伝票件数：{shippingCount}</div>
							<div>商品計：{items.reduce((sum, item) => sum + parseInt(String(item.quantity), 10), 0)}</div>
						</section>
					}
				</div>
			)}
		</>
	);
}
export default PrintTotalPickingList;