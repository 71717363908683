export const METHOD_TABLE: Record<string, string> = {
	'rakuten': '楽天',
	'rakuten2': '楽天2',
	'yahoo': 'Yahoo!',
	'prefer.jp': 'prefer.jp',
	'shopify': 'Shopify',
	'amazon': 'Amazon',
	'manually': '手動登録',
	'komarijp.com': 'komarijp.com',
	'review': 'レビュー特典',
	'wowma': 'au',
	'dshopping': 'dショッピング'
};

export const SHIPPING_STATE = {
	pending: '保留',
	waiting: '出荷待ち',
	shipping: '出荷作業中',
	shipped: '出荷済み',
};

export const MOVING_STATE_LIST = ['moving', 'done'] as const;
export const MOVING_STATE: Record<string, string> = {
	'moving': '移動中',
	'done': '完了',
};

export const SHIPPING_FILTER_SKU_SELECT = [
	{ sku: '00024392', name: 'ぷりふあ五月人形 カタログ', omitSku: false },
	{ sku: '00023866', name: '小さなおひな様 ぷりふあ カタログ', omitSku: false },
	{ sku: '00029605,00029606,00029607,00029608,00029609,00029611,00029610,00029612,00029613,00030356,00030357,00030358,00030359,00030360,00030361,00030362,00030363,00030364,00030365,00030366,00030367,00030368,00030369,00030370,00030371,00030372,00030373', name: '五月丸型ケース', omitSku: true },
	{ sku: '00029614,00029615,00029616,00029617,00029618,00029620,00029619,00029621,00029622,00030374,00030375,00030376,00030377,00030378,00030379,00030380,00030381,00030382,00030383,00030384,00030385,00030386,00030387,00030388,00030389,00030390,00030391', name: '五月四角ケース', omitSku: true },
	{ sku: '00025967,00025968,00025969,00025970,00029634,00029635,00029636,00029637,00029638,00029639,00029640,00029641,00025973,00025975,00025977,00029650,00029652,00029654,00029656,00029658,00029660', name: '三月丸型ケース', omitSku: true },
	{ sku: '00029502,00029503,00029504,00029505,00029642,00029643,00029644,00029645,00029646,00029647,00029648,00029649', name: '三月四角ケース', omitSku: true },
]