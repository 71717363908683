import { z } from "zod";

export interface Person {
	/** 氏名 */
	name: string;
	/** 氏名読みがな */
	kana: string;
	/** 郵便番号 */
	zip: string;
	/** 国名 */
	country: string;
	/** 都道府県(country直下の住所) */
	pref: string;
	/** 市区町村(pref直下の住所) */
	city: string;
	/** 番地(city以降の住所) */
	address: string;
	/** 建物(address以降の住所) */
	subaddress: string;
	/** 会社名(subaddress以降の住所) */
	company: string;
	/** 部署名(company以降の住所) */
	section: string;
	/** 電話番号 */
	phone: string;
	/** 離島フラグ */
	isolatedIsland: boolean;
}
export interface Detail {
	/** 配送日時指定 */
	specifiedDeliveryDate: string;
	/** のし表書き */
	noshiAddress: string;
	/** のし名入れ */
	noshiName: string;
	/** ラッピング */
	wrapping: boolean;
	/** 配送会社名 */
	deliveryCompanyName: string;
	/** 支払方法名 */
	settlementMethodName: string;
	/** 総請求額 */
	requestPrice: number;
	/** 送料 */
	postagePrice: number;
	/** 代引き手数料 */
	deliveryPrice: number;
	/** バイヤーコメント */
	buyerComments: string;
	/** セラーコメント */
	sellerComments: string;
	/** 備考 */
	remarks: string;
	/** 備考解析失敗 */
	failedParseRemarks?: boolean;
	/** 請求書有無 */
	billSlip?: boolean;
	/** 明細書有無 */
	detailedSlip?: boolean;
	/** 領収書有無 */
	receipt?: boolean;
}
export interface TimeZone {
	/** 時間帯from */
	from: number;
	/** 時間帯to */
	to: number;
	
}

const schemaSasagaseAddress = z.object({
	name: z.string(),
	kana: z.string(),
	zip: z.string(),
	country: z.string(),
	pref: z.string(),
	city: z.string(),
	address: z.string(),
	subaddress: z.string(),
	company: z.string(),
	section: z.string(),
	phone: z.string(),
});
export type SasagaseAddress = z.infer<typeof schemaSasagaseAddress>;

const schemaReviewOrder = z.object({
	method: z.string(),
	idtext: z.string(),
	state: z.unknown().array(),
	orderer: schemaSasagaseAddress,
	shippings: z.object({
		dest: schemaSasagaseAddress,
		method: z.string(),
		tracking: z.string(),
		delivery_date: z.object({
			date: z.string(),
			timezone: z.string(),
			shipping: z.string(),
		}),
		cod: z.number(),
		desc: z.unknown(),
		items: z.object({
			sku: z.string(),
			attributes: z.object({
				key: z.string(),
				value: z.string(),
			}).array(),
			quantity: z.number(),
		}).array(),
	}).array(),
});
export type ReviewOrder = z.infer<typeof schemaReviewOrder>;

export const schemaCreateOrderData = z.object({
	method: z.string(),
	idtext: z.string().optional(),
}).and(schemaReviewOrder);
	
export type CreateOrderData = z.infer<typeof schemaCreateOrderData>;
