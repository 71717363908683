import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { useLocation } from 'react-router-dom';
import { useCSV, useLocationGroup, useShipping, useStore } from '../../../hooks';
import { Shipping } from '../../../types';
import { PickingList } from '../../organisms/PickingList';

export const PrintPickingList: React.FC = () => {
	const [container, setContainer] = React.useState<HTMLDivElement|null>(null);
	const [shippings, setShippings] = React.useState<Shipping[]|null>(null);
	const location = useLocation();

	const [locationGroup] = useLocationGroup();
	const [, , , , , shippingMethods] = useShipping(locationGroup?.id, {});
	const { parseDate } = useCSV();
	const settings = useStore();

	const params = new URLSearchParams(location.search);
	const ids = params.getAll('ids');
	const skuPrefix = params.get('skuPrefix') ?? '';
	const shippingPrefix = params.get('shippingPrefix') ?? '';

	React.useEffect(() => {
		// CSS削除
		document.head.querySelectorAll("link").forEach(link => link.remove());
		const css = document.createElement("link");
		css.rel = "stylesheet";
		css.href = `/css/pickinglist-print.css`;
		document.head.appendChild(css);

		// title調整
		const now = parseDate(new Date());
		document.title = `ピッキングリスト-${now?.year}${now?.mon}${now?.day}${now?.hour}${now?.min}`;

		while(document.body.lastChild){
			document.body.removeChild(document.body.lastChild);
		}
		const div = document.createElement("div");
		document.body.appendChild(div);

		setContainer(div);
	}, []);

	React.useEffect(() => {
		if (ids.length <= 0) {
			return;
		}

		const load = async() => {
			const ss = await shippingMethods.getShippings(ids);
			setShippings(ss);
		};

		load();

	}, []);

	React.useEffect(() => {
		if (!shippings || !container || !settings) {
			return;
		}

		const pages = shippings.map(shipping => {
			return (
				<PickingList key={shipping.id} shipping={shipping} skuPrefix={skuPrefix} shippingPrefix={shippingPrefix} settings={settings} />
			);
		});

		const root = createRoot(container);
		root.render(pages);

	}, [shippings, container, settings]);

	return (
		<>Now Loading...</>
	);
}
export default PrintPickingList;